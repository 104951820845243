import React from 'react';
import MaterialIcon from '../MaterialIcon/MaterialIcon';

const MaterialIconList = ({ icons = [], theme, onClickIcon, searchValue }) => {
  const urlParams = new URLSearchParams(window.location.search);
  let queryParamColor = urlParams.get('iconColor');

  const filteredIconsList = icons.filter((i) => {
    return (
      i.theme === theme &&
      i.searchable.toLowerCase().includes(searchValue.toLowerCase().trim())
    );
  });
  const colors = ['#84A9D9', '#8FCBD9', '#F2D091', '#F2B077', '#F29877'];
  const renderIcons = () => {
    let counter = 0;
    const getColor = () => {
      if (counter >= colors.length) {
        counter = 0;
      }
      let color = colors[counter];
      counter++;
      return color;
    };

    if (queryParamColor) {
      queryParamColor = '#' + queryParamColor;
    }

    return filteredIconsList.map((icon, index) => (
      <MaterialIcon
        key={index}
        icon={icon}
        hideLabelBackdrop={Boolean(queryParamColor)}
        color={queryParamColor ? queryParamColor : getColor()}
        onClickIcon={onClickIcon}
      />
    ));
  };
  return (
    <div
      className="icons-list-container"
      style={{ height: 'calc(100% - 80px)' }}
    >
      <div className="icons-list-dimmed"></div>
      <ul className="icons-list">{renderIcons()}</ul>
    </div>
  );
};

export default MaterialIconList;
