import React from 'react';

const MaterialIcon = ({
  icon,
  onClickIcon,
  color = '#0072e5',
  hideLabelBackdrop,
}) => {
  const { importName, name, theme, Component } = icon;
  const handleIconClick = () => {
    onClickIcon(icon);
  };
  const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  };
  return (
    <li className="icon" onClick={handleIconClick}>
      <Component className="icon-svg" htmlColor={color} fontSize="large" />
      <label
        style={{
          backgroundColor: hideLabelBackdrop
            ? 'transparent'
            : hex2rgba(color, 0.25),
        }}
      >
        {name}
      </label>
    </li>
  );
};

export default MaterialIcon;
