import React from 'react';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';
import './SearchBar.css';

const SearchBar = ({ searchValue, onChange, isSearching }) => {
  return (
    <div className="search-bar">
      <TextField
        fullWidth
        label="Search Material Icons"
        id="main-search-bar"
        value={searchValue}
        onChange={onChange}
      />
      {isSearching && (
        <div className="is-searching-indicator">
          <CircularProgress
            sx={{ animationDuration: '0.65s' }}
            size={25}
            disableShrink
          />
        </div>
      )}
    </div>
  );
};

export default SearchBar;
