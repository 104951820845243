import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import { SegmentedControl } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';

const ThemePicker = ({ iconTypes, onThemeChange, theme }) => {
  const [value, setValue] = useState(theme);
  const [debouncedThemeChange] = useDebouncedValue(value, 50);

  const data = [...iconTypes].map((themeLabel) => {
    return { label: themeLabel, value: themeLabel };
  });

  const renderThemePicker = () => {
    const themes = [...iconTypes];

    return themes.map((themeLabel, index) => {
      const isActiveTheme = themeLabel === theme;
      return (
        <Chip
          key={index}
          label={themeLabel}
          className="theme-chip"
          onClick={(e) => onThemeChange(themeLabel)}
          variant={isActiveTheme ? '' : 'outlined'}
          color="primary"
        />
      );
    });
  };

  useEffect(() => {
    document.querySelector('.icons-list-dimmed').classList.add('active');
  }, [value]);

  useEffect(() => {
    onThemeChange(debouncedThemeChange);
    document.querySelector('.icons-list-dimmed').classList.remove('active');
  }, [debouncedThemeChange]);

  return (
    <div className="theme-picker" style={{ marginTop: '15px' }}>
      <SegmentedControl
        fullWidth
        value={value}
        onChange={setValue}
        data={data}
        size="md"
        sx={{
          borderRadius: '4px 4px 0px 0px',
        }}
      />
    </div>
  );
};

export default ThemePicker;

/* 
[
          { label: 'React', value: 'react' },
          { label: 'Angular', value: 'ng' },
          { label: 'Vue', value: 'vue' },
          { label: 'Svelte', value: 'svelte' },
        ]
    */
