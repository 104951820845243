import React from 'react';
import { Divider, IconButton, Modal, Tooltip, Typography } from '@mui/material';
// import copy from "clipboard-copy";
import './ActiveIconModal.css';
import { Prism } from '@mantine/prism';

const ActiveIconModal = ({ icon, onClose }) => {
  const { name, Component, importName, searchable } = icon;

  return (
    <Modal open={!!icon} onClose={onClose}>
      <div className="modal">
        <div className="icon-container">
          <Component sx={{ color: '#495057', fontSize: '75px' }} />
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div className="icon-data">
            <Typography>Name:</Typography>
            <Typography variant="h5" color="#495057">
              {importName}
            </Typography>

            <Typography>JSX:</Typography>
            <Prism withLineNumbers language="tsx">{`<${importName} />`}</Prism>
            <Typography>Icon Button:</Typography>
            <div>
              <IconButton>
                <Component />
              </IconButton>
            </div>
            <Typography>Colors:</Typography>
            <div className="icon-color-list">
              {[
                'inherit',
                'primary',
                'secondary',
                'error',
                'info',
                'success',
                'warning',
              ].map((color, index) => (
                <Tooltip key={index} title={color} placement="bottom">
                  <span>
                    <Component color={color} />
                  </span>
                </Tooltip>
              ))}
            </div>
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
          <Typography sx={{ marginBottom: '10px' }}>Import Strings:</Typography>
          <Prism className="code-snippet" withLineNumbers language="tsx">
            {`import { ${importName} } from "@mui/icons-material";`}
          </Prism>
        </div>
      </div>
    </Modal>
  );
};

export default ActiveIconModal;
