import React, { useEffect, useState } from 'react';
import './styles.css';
import * as mui from '@mui/icons-material';
import synonyms from './synonyms';
import MaterialIconList from './components/MaterialIconList/MaterialIconList';
import ThemePicker from './components/ThemePicker/ThemePicker';
import SearchBar from './components/SearchBar/SearchBar';
import ActiveIconModal from './components/ActiveIconModal/ActiveIconModal';
import { useDebouncedValue } from '@mantine/hooks';

export default function App() {
  const iconTypes = new Set();
  const allIconsMap = {};
  const allIcons = Object.keys(mui)
    .sort()
    .map((importName) => {
      let theme;
      if (importName.indexOf('Outlined') !== -1) {
        theme = 'Outlined';
      } else if (importName.indexOf('TwoTone') !== -1) {
        theme = 'Two tone';
      } else if (importName.indexOf('Rounded') !== -1) {
        theme = 'Rounded';
      } else if (importName.indexOf('Sharp') !== -1) {
        theme = 'Sharp';
      } else {
        theme = 'Filled';
      }
      iconTypes.add(theme);
      const name = importName.replace(/(Outlined|TwoTone|Rounded|Sharp)$/, '');
      let searchable = name;
      if (synonyms[searchable]) {
        searchable += ` ${synonyms[searchable]}`;
      }

      const icon = {
        importName,
        name,
        searchable,
        theme,
        Component: mui[importName],
      };
      allIconsMap[importName] = icon;
      return icon;
    });

  const [activeTheme, setActiveTheme] = useState('Filled');
  const [activeIcon, setActiveIcon] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 500);

  const handleClickIcon = (icon) => {
    setActiveIcon(icon);
    return;
  };
  const handleSearchChange = (e) => {
    const { value } = e?.target;
    setSearchValue(value);
    setIsSearching(true);
  };
  const handleCloseModal = () => {
    setActiveIcon(null);
  };

  useEffect(() => {
    setIsSearching(false);
  }, [debouncedSearchValue]);

  const [isEmbedMode, setIsEmbedMode] = useState(false);

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    if (search.get('embedMode') === 'true') {
      setIsEmbedMode(true);
    }
  }, []);
  return (
    <div
      className="app"
      style={isEmbedMode ? { margin: 0, marginTop: '10px' } : {}}
    >
      <SearchBar
        isSearching={isSearching}
        searchValue={searchValue}
        onChange={handleSearchChange}
      />
      <ThemePicker
        iconTypes={iconTypes}
        theme={activeTheme}
        onThemeChange={setActiveTheme}
      />
      <MaterialIconList
        icons={allIcons}
        theme={activeTheme}
        onClickIcon={handleClickIcon}
        searchValue={debouncedSearchValue}
      />
      {activeIcon && (
        <ActiveIconModal icon={activeIcon} onClose={handleCloseModal} />
      )}
    </div>
  );
}
